import config_prod from '@crystal-eyes/constants.production'
import config_staging from '@crystal-eyes/constants.staging'
import config_development from '@crystal-eyes/constants.development'
import config_local from '@crystal-eyes/constants.local'
import config_integration from '@crystal-eyes/constants.integration'
import config_test from '@crystal-eyes/constants.test'

type AppEnv = 'production' | 'staging' | 'development'
const appEnv: AppEnv = (process.env.APP_ENV as AppEnv) || 'development'

const configs: any = {
  production: config_prod,
  staging: config_staging,
  development: config_development,
  local: config_local,
  integration: config_integration,
  test: config_test,
}

if (!configs[appEnv]) {
  throw new Error(`Unknown App Environment: ${appEnv}`)
}

const { authTokenName, sessionTokenName } = configs[appEnv]

export const CRYSTAL_AUTH_TOKEN =
  process.env.AUTH_COOKIE_AUTH || authTokenName || 'CRYSTAL_AUTH_TOKEN'
export const CRYSTAL_SESSION_TOKEN =
  process.env.AUTH_COOKIE_SESSION || sessionTokenName || 'CRYSTAL_SESSION_TOKEN'

export const CHROME_INSTALL_URL =
  'https://chrome.google.com/webstore/detail/crystal/nmaonghoefpmlfgaknnboiekjhfpmajh?hl=en'
export const PARAGON_PROJECT_ID = '14de3eed-e157-44aa-8ddf-4c1eb79804ef'
export const PARAGON_USER_LEVEL_PROJECT_ID =
  '9f8760d2-aed3-4044-a107-d54c6d5baea8'

export const AUTH_TOKEN_COOKIE = CRYSTAL_AUTH_TOKEN
export const AUTH_REFRESH_COOKIE = 'crystl-auth-refresh'
